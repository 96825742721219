import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: '/workOrderDistribution/updateWorkOrderDistribution',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "msg": "成功"
            })
        }
    },
    {
        url: '/workOrderDistribution/getAlarmDetails',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|1": [{
                    "workOrderDistributionId|+1": 1,
                    "areaId": 1,
                    "remark|1": ["xxxxxxx1", "xxxxxxx2", "xxxxxxx3", "xxxxxxx4"],
                    "state|1": [10, 15],
                    "distributeTime": "@date('yyyy-MM-dd HH:mm:ss')",
                    "imgUrl": "@url",
                    "disposeTime": "@date('yyyy-MM-dd HH:mm:ss')",
                    "abnormalCause": "XXXXXXXXXX",
                    "emphasisTime": "@date('yyyy-MM-dd HH:mm:ss')",
                    "handlingTime": "@date('yyyy-MM-dd HH:mm:ss')",
                    "highLevelReport|1": true,
                    "sysUserDistribute": "@cname",
                    "disposeExplain|1": ["xxxxxxx1", "xxxxxxx2", "xxxxxxx3", "xxxxxxx4"],
                    "areaName|1": ["氯气库房", "液氯站房", "制氢站", "复蒸房", "光纤四氯化错房"],
                    "sonsorid": 1,
                    "sonsorName|1": ["气体传感器1", "气体传感器2", "气体传感器3", "气体传感器4", "气体传感器5"],
                    "isEmphasisAlarm|1": true,
                    "sysUserDisposeName": "@cname",
                    "sysUserDispose": "@cname",
                    "sysUserDistributeName": "@cname",
                    "alarmProgress|1": ["报警发生", "工单派发", "重点报警上报", "报警处理"],
                    "operationReport|1": true,
                }],
            })
        }
    },
    {
        url: '/workOrderDistribution/getAlarmDisposeDetails',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|1": [{
                    "workOrderDistributionId|+1": 1,
                    "areaId": 1,
                    "areaName|1": ["氯气库房", "液氯站房", "制氢站", "复蒸房", "光纤四氯化错房"],
                    "sonsorName|1": ["气体传感器1", "气体传感器2", "气体传感器3", "气体传感器4", "气体传感器5"],
                    "alarmOccurrenceTime": "@date('yyyy-MM-dd HH:mm:ss')",
                    "remark|1": ["XXXXXXX1", "XXXXXX2", "XXXXXX3", "XXXXXX4", "XXXXXX5"],
                    "disposeExplain|1": ["xxxxxxx1", "xxxxxxx2", "xxxxxxx3", "xxxxxxx4"],
                    "abnormalCause|1": ["xxxxxxx1", "xxxxxxx2", "xxxxxxx3", "xxxxxxx4"],
                    "imgUrl": "@url",
                    "reportType": 1,
                }],
            })


        }
    },
    {
        url: '/workOrderDistribution/getWorkOrderDistributionDetail',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|1": [{
                    "workOrderDistributionId|+1": 1,
                    "areaId": 1,
                    "areaName|1": ["氯气库房", "液氯站房", "制氢站", "复蒸房", "光纤四氯化错房"],
                    "sonsorName|1": ["气体传感器1", "气体传感器2", "气体传感器3", "气体传感器4", "气体传感器5"],
                    "sonsorid": 1,
                    "alarmOccurrenceTime": "@date('yyyy-MM-dd HH:mm:ss')",
                    "remark|1": ["xxxxxxx1", "xxxxxxx2", "xxxxxxx3", "xxxxxxx4"],
                    "sysUserDispose": "@cname",
                    "sysUserDisposeName": "@cname",
                    "rectificationTime": "@date('yyyy-MM-dd HH:mm:ss')",
                    "content|1": ["xxxxxxx1", "xxxxxxx2", "xxxxxxx3"]
                }],
            })
        }
    },
    {
        url: '/user/getTheManAtTheWheel',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|3-5": [{
                    "sysUserId|1": [2, 4, 1, 3],
                    "nickName|1": "@cname"
                }],
            })


        }
    },
    {
        url: '/workOrderDistribution/getAlarmProcessingPage',
        type: 'post',
        response: (option: paramsType) => {
            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "records|100": [{
                        "workOrderDistributionId|+1": 1,
                        "areaId": 1,
                        "sonsorid": 1,
                        "areaName|1": ["氯气库房", "液氯站房", "制氢站", "复蒸房", "光纤四氯化错房"],
                        "sonsorName|1": ["气体传感器1", "气体传感器2", "气体传感器3", "气体传感器4", "气体传感器5"],
                        "remark|1": ["xxxxxxx1", "xxxxxxx2", "xxxxxxx3", "xxxxxxx4"],
                        "state|1": [10, 15],
                        "alarmOccurrenceTime": "@date('yyyy-MM-dd HH:mm:ss')"
                    }]
                },
                "total": 100,
                "size": 15
            })
            responseData.data.records = responseData.data.records.slice(start, end)
            return responseData
        }
    },
    {
        url: '/workOrderDistribution/getReportWorkOrderDistribution',
        type: 'post',
        response: (option: paramsType) => {
            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "records|100": [{
                        "workOrderDistributionId|+1": 1,
                        "areaId": 1,
                        "sonsorid": 1,
                        "areaName|1": ["氯气库房", "液氯站房", "制氢站", "复蒸房", "光纤四氯化错房"],
                        "sonsorName|1": ["气体传感器1", "气体传感器2", "气体传感器3", "气体传感器4", "气体传感器5"],
                        "remark|1": ["xxxxxxx1", "xxxxxxx2", "xxxxxxx3", "xxxxxxx4"],
                        "state|1": [5, 10, 15],
                        "alarmOccurrenceTime": "@date('yyyy-MM-dd HH:mm:ss')"
                    }]
                },
                "total": 100,
                "size": 15
            })
            responseData.data.records = responseData.data.records.slice(start, end)
            return responseData
        }
    },
    {
        url: '/workOrderDistribution/getWorkOrderDistribution',
        type: 'post',
        response: (option: paramsType) => {
            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "records|100": [{
                        "workOrderDistributionId|+1": 1,
                        "areaId": 1,
                        "sonsorid": 1,
                        "areaName|1": ["氯气库房", "液氯站房", "制氢站", "复蒸房", "光纤四氯化错房"],
                        "sonsorName|1": ["气体传感器1", "气体传感器2", "气体传感器3", "气体传感器4", "气体传感器5"],
                        "remark|1": ["xxxxxxx1", "xxxxxxx2", "xxxxxxx3", "xxxxxxx4"],
                        "state|1": [5, 10, 15],
                        "alarmOccurrenceTime": "@date('yyyy-MM-dd HH:mm:ss')"
                    }]
                },
                "total": 100,
                "size": 15
            })
            responseData.data.records = responseData.data.records.slice(start, end)
            return responseData
        },

    },
    {
        url: '/workOrderDistribution/updateAlarmDispose',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "msg": "成功"
            })
        }
    },
    /************** 报警分析  *****************/
    {
        url: '/workOrderDistribution/getAlarmNum',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "toDayAlarmNum|0-100": 1,
                    "toMonthAlarmNum|0-100": 1,
                    "toYearAlarmNum|0-100": 1,
                    "pastDayAlarmNum|0-100": 1,
                    "pastMonthAlarmNum|0-100": 1,
                    "pastYearAlarmNum|0-100": 1,
                    "dayAlarTrend|1": '@float(0,100,0,2)',
                    "monthAlarmTrend": '@float(0,100,0,2)',
                    "yearAlarmTrend": '@float(0,100,0,2)'
                }
            })
        }
    },
    {
        url: '/workOrderDistribution/getAlarmClass',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "alarmClass|7": [
                    {
                        "alarm|1": ["液氯库房", "光纤复蒸房", "光纤复蒸房", "制氢站房", "变电房"],
                        "percent": "@float(0,100,0,1)"
                    }
                ]
            })
        }
    },
    {
        url: '/workOrderDistribution/getAlarmStatistics',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|20": [
                    {
                        "alarmNum|50-200": 50,
                        "createTime": "@date(yyyy-MM-dd HH:mm:ss)"
                    }
                ]
            })
        }
    },
    {
        url: '/workOrderDistribution/getAlarmRank',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|8": [
                    {
                        "sort|+1": 1,
                        "areaName|1": ["液氯库房", "光纤复蒸房", "光纤复蒸房", "制氢站房", "变电房"],
                        "sonsorNum|1-100": 1,
                        "alarmNum|1-100": 1
                    }
                ]
            })
        }
    },
]