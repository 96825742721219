import Mock from 'mockjs'

export type MenuItemType = {
  id: string;
  name: string;
  icon?: string;
  link: string;
  children?: MenuItemType[]
}

const MenuList: MenuItemType[] = [
  {
    id: '1',
    name: '首页',
    icon: 'md-home',
    link: '/main/home',
  },
  {
    id: '2',
    name: '数据监控',
    icon: 'md-desktop',
    link: '',

    children: [
      {
        id: '2-1',
        name: '实时数据',
        link: '/main/monitor/actime',
      },
      {
        id: '2-2',
        name: '历史数据',
        link: '/main/monitor/history',
      },
    ],
  },
  {
    id: '3',
    name: '报警管理',
    icon: 'md-volume-up',
    link: '',

    children: [
      {
        id: '3-1',
        name: '报警列表',
        link: '/main/alarm/list',
      },
      {
        id: '3-2',
        name: '报警处理',
        link: '/main/alarm/handle',
      },
      {
        id: '3-3',
        name: '重点报警',
        link: '/main/alarm/keypoint',
      },
      {
        id: '3-4',
        name: '报警分析',
        link: '/main/alarm/analysis',
      },
    ],
  },
  {
    id: '4',
    name: '运维管理',
    icon: 'md-browsers',
    link: '',

    children: [
      {
        id: '4-1',
        name: '巡检计划',
        link: '/main/inspection/plan',
      },
      {
        id: '4-2',
        name: '巡检任务',
        link: '/main/inspection/task',
      },
      {
        id: '4-3',
        name: '巡检记录',
        link: '/main/inspection/record',
      },
    ],
  },

  {
    id: '5',
    name: '报告分析',
    icon: 'md-document',
    link: '/main/report/',

    children: [
      {
        id: '5-1',
        name: '月度报告',
        link: '/main/report/monthly',
      },
    ],
  },
  {
    id: '6',
    name: '参数设置',
    icon: 'md-settings',
    link: '',

    children: [
      {
        id: '6-1',
        name: '基础数据维护',
        link: '/main/params/base',
      },
      {
        id: '6-2',
        name: '报警参数设置',
        link: '/main/params/alarm',
      },
    ],
  },
  {
    id: '7',
    name: '用户管理',
    icon: 'md-person',
    link: '',

    children: [
      {
        id: '7-1',
        name: '帐号管理',
        link: '/main/users/account',
      },
      {
        id: '7-2',
        name: '角色权限',
        link: '/main/users/role',
      },
      {
        id: '7-3',
        name: '组织管理',
        link: '/main/users/department',
      },
    ],
  },
  {
    id: '8',
    name: '日志管理',
    icon: 'md-calendar',
    link: '',

    children: [
      {
        id: '8-1',
        name: '系统日志',
        link: '/main/log',
      },
    ],
  },
];

export default [
  {
    url: "api/menu/get",
    type: "post",
    response: () => {
      return Mock.mock({
        "code": 0,
        "data": {
          "menu": MenuList
        }
      })
    }
  },
];