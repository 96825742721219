import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
    state: () => {
        return {
            company: '驰宏锗业',
            collapsed: false,
            menuState: true,
            menuActive: ["1"] as string[],
            menuOpenKeys: [''] as string[],
            alarmSentData: {}
        }
    },
    actions: {
        changeCollap() {
            this.collapsed = !this.collapsed
        },
        changeMenu() {
            this.menuState = !this.menuState
        },

        changeMenuActive(MenuItem: string[]) {
            this.menuActive = MenuItem
        },

        onOpenChange(openKeys: string[]) {

        },

        toAlarmSentPageData(data: object) {
            this.alarmSentData = data
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage,
            },
        ]
    }
})
