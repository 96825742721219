<template>
  <RouterView />
</template>

<script lang="ts" setup></script>

<style lang="less" scoped>
::v-deep {
  .ivu-modal-wrap {
    .ivu-modal {
      .ivu-modal-content {
        background-color: #000 !important;
        .ivu-modal-header,
        .ivu-modal-body {
          border-bottom: 0 !important;
        }
        .ivu-modal-body {
          padding: 2px;
          p {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
