type Permission = {
    id: number;
    name: string;
    path: string;
    pass?: boolean;
}
export const permission: Permission[] = [
    {
        id: 1,
        name: "首页",
        path: "home",
    },
    {
        id: 2,
        name: "数据监控",
        path: "monitor",
    },
    {
        id: 3,
        name: "报警管理",
        path: "alarm",
    },
    {
        id: 4,
        name: "运维管理",
        path: "inspection",
    },
    {
        id: 5,
        name: "报告分析",
        path: "report",
    },
    {
        id: 6,
        name: "参数设置",
        path: "params",
    },
    {
        id: 7,
        name: "用户管理",
        path: "users",
    },
    {
        id: 8,
        name: "日志管理",
        path: "log",
    },
];


export default permission;