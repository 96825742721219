import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: '/workOrderDistribution/getOverview',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "overview": {
                    "facility": 22,
                    "alarm": 29,
                    "alarm_today": 47,
                    "patrol": 40,
                    "unprocessed": 19,
                    "unprocessed_today": 92
                }
            })
        }
    },
    {
        url: '/workOrderDistribution/getAlarmClass',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "alarm_class|7": [{
                    "alarm|1": ["液氯库房", "光纤复蒸房", "制氢站房"],
                    "percent": "@float(1,100,0,2)"
                }]
            })
        }
    },
    {
        url: '/workOrderDistribution/getFacilityAlarmMonth',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "facility_alarm_month|20": [{
                    "date": "2012-12-12 12:12:12",
                    "position": "报警内容",
                    "status|1": [5, 10, 15]
                }]
            })
        }
    },
    {
        url: '/workOrderDistribution/getRecentWeek',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "recent_week|7": [{
                    "date": "2012-12-21 12:12:12",
                    "processed|20-200": 1,
                    "unprocess|20-200": 1
                }]
            })
        }
    },
    {
        url: '/sonsor/getDistribution',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "alarm_class|8": [{
                    "count|5-20": 10,
                    "facility|+1": ["蒸馏房", "光纤四氯化错房", "区熔还原房", "制氢站", "罐装房", "液氯库", "变电房", "复蒸房",]
                }]
            })
        }
    },
    {
        url: '/sonsor/getFacilityStatus',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "alarm_class|7": {
                    "normal|5-20": 10,
                    "alarm|5-20": 10,
                    "offline|5-20": 10
                }
            })
        }
    },
]