import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: 'api/mock/charge/man/data',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|4": [{
                    "sysUserId|+1": 1,
                    "nickName": "dingding"
                }]
            })
        }
    }
]