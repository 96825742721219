import { RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
    {
        path: 'log',
        meta: {
            title: '系统日志'
        },
        component: () => import('@/pages/log/index.vue')
    }
];

export default routes;