import permission from '@/router/map';
import { defineStore } from 'pinia'
import routeMap from "@/router/map";
import router from '@/router/index'
import { createRouter, createWebHistory, RouteRecordRaw, useRouter } from 'vue-router'



// 自动引入模块目录下内容(该方法适用于webpack)
const AutoImportModules = (): Array<RouteRecordRaw> => {
    let routeArr: Array<RouteRecordRaw> = [];
    const moduleFile = require.context("@/router/modules/", true, /\.ts$/);
    moduleFile.keys().map((file) => {
        // 剔除公共目录
        if (file.indexOf("@/router/common.ts") > -1) return null;
        routeArr.push(...moduleFile(file).default);
    });

    return routeArr;
};



export const useUserinfoStore = defineStore('userinfo', {
    state: () => {
        return {
            permission: [],
            organization: {},
            role: {},
            userinfo: {}
        }
    },
    actions: {
        SET_PERMISSION(permissionArr: []) {
            this.permission = permissionArr
        },
        SET_ORGANIZATION(organization: {}) {
            this.organization = organization
        },
        SET_ROLE(role: {}) {
            this.role = role
        },
        SET_USERINFO(user: {}) {
            this.userinfo = user;
        },

        // REMOVE_PERMISSION() {
        //     this.permission = [];
        // },
        // REMOVE_ROLE() {
        //     this.role = {}
        // },
        // REMOVE_ORGANIZATION() {
        //     this.organization = {}
        // },

        REMOVE_USERINFO() {
            this.permission = [];
            this.userinfo = {};
            this.role = {}
            this.organization = {}
        },
        MATCH_ROUTE() {
            let notPermissionPath = this.permission.map((item: any) => {
                if (item.selectAuth !== 0) {
                    let notUrl = routeMap.find((r) => r.id === item.authType)?.path;
                    return notUrl;
                }
            })
            let menuListArr = AutoImportModules();
            menuListArr.forEach((item) => {
                if (!item.path.split("/")[0]) {
                    router.addRoute("/", item);
                    return
                }

                if (notPermissionPath.includes(item.path.split("/")[0])) return;
                router.addRoute("main", item);
            });
        },
        MATHCH_AUTH(id: number, type: string, next: Function) {
            let types: string
            this.permission.map((item: any) => {
                if (item.authType === id) {
                    switch (type) {
                        case "add":
                            if (item.addAuth !== 0) {
                                window.location.href = "/main/404"
                            } else {
                                next()
                            }
                            break;
                        case "edit":
                            if (item.updateAuth !== 0) {
                                window.location.href = "/main/404"
                            } else {
                                next()
                            }
                            break;
                    }
                }
            })
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                storage: localStorage,
            },
        ]
    }
})



const hasPermission = (permission: string | string[]) => {

}