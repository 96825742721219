import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: '/user/getUserAll',
        type: 'post',
        response: (option: paramsType) => {

            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "total": 100,
                    "size": 15,
                    "records|100": [{
                        "username": "用户账号",
                        "sysUserId|1-100": 1,
                        "nickName": "用户名称",
                        "accountOpening|0-1": 0,
                        "sysRoleId": 1,
                        "sysRoleName": "角色名称",
                        "orgId": 1,
                        "orgName": "组织名称"
                    }]
                },
            })
            responseData.data.records = responseData.data.records.slice(start, end)
            return responseData
        },
    },
    {
        url: '/user/addUser',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },
    {
        url: '/user/updateUser',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },
    {
        url: '/user/deleteUser',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },
    {
        url: '/user/getUserByUsername',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "username": "用户账号",
                    "sysUserId": 1,
                    "nickName": "用户名称",
                    "accountOpening": 0,
                    "sysRoleId": 1,
                    "sysRoleName": "角色名称",
                    "orgId": 1,
                    "orgName": "组织名称"
                },
            })
        },
    },
    {
        url: '/user/login',
        type: 'post',
        response: (option: paramsType) => {
            console.log(option);

            return Mock.mock({
                "code": 0,
                "data": {
                    "user": {
                        "sysUserId": 1,
                        "username": "用户账号",
                        "nickName": "用户名称",
                        "accountOpening": 0,
                        "sysRoleId": 1,
                        "orgId": 1,
                        "initialPassword": 0
                    },
                    "org": {
                        "orgId": 1,
                        "orgNumber": 1,
                        "orgName": "组织名称",
                        "orgType": "组织类型",
                        "checkAll": 0
                    },
                    "role": {
                        "sysRoleId": 1,
                        "sysRoleName": "角色名称",
                        "remark": "备注",
                        "status": 0
                    },
                    "auth": [
                        {
                            "sysAuthId": 1,
                            "sysRoleId": 1,
                            "authType": 1,
                            "authName": "首页",
                            "selectAuth": 0,
                            "addAuth": -1,
                            "updateAuth": -1,
                            "deleteAuth": -1,
                            "exportAuth": -1
                        },
                        {
                            "sysAuthId": 2,
                            "sysRoleId": 1,
                            "authType": 2,
                            "authName": "数据监控",
                            "selectAuth": 0,
                            "addAuth": 0,
                            "updateAuth": -1,
                            "deleteAuth": 0,
                            "exportAuth": -1
                        },
                        {
                            "sysAuthId": 3,
                            "sysRoleId": 1,
                            "authType": 3,
                            "authName": "报警管理",
                            "selectAuth": 0,
                            "addAuth": 0,
                            "updateAuth": 0,
                            "deleteAuth": 0,
                            "exportAuth": -1
                        },
                        {
                            "sysAuthId": 4,
                            "sysRoleId": 1,
                            "authType": 4,
                            "authName": "运维管理",
                            "selectAuth": 0,
                            "addAuth": 0,
                            "updateAuth": 0,
                            "deleteAuth": 0,
                            "exportAuth": -1
                        },
                        {
                            "sysAuthId": 5,
                            "sysRoleId": 1,
                            "authType": 5,
                            "authName": "报告分析",
                            "selectAuth": 0,
                            "addAuth": 0,
                            "updateAuth": 0,
                            "deleteAuth": 0,
                            "exportAuth": -1
                        },
                        {
                            "sysAuthId": 6,
                            "sysRoleId": 1,
                            "authType": 6,
                            "authName": "参数设置",
                            "selectAuth": 0,
                            "addAuth": 0,
                            "updateAuth": 0,
                            "deleteAuth": 0,
                            "exportAuth": -1
                        },
                        {
                            "sysAuthId": 7,
                            "sysRoleId": 1,
                            "authType": 7,
                            "authName": "用户管理",
                            "selectAuth": 0,
                            "addAuth": -1,
                            "updateAuth": -1,
                            "deleteAuth": -1,
                            "exportAuth": 0
                        },
                        {
                            "sysAuthId": 8,
                            "sysRoleId": 1,
                            "authType": 8,
                            "authName": "日志管理",
                            "selectAuth": 0,
                            "addAuth": -1,
                            "updateAuth": -1,
                            "deleteAuth": -1,
                            "exportAuth": -1
                        }

                    ]
                },
            })
        },
    },
    {
        url: '/user/resetUserPassword',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },









    {
        url: '/auth/updateAuth',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },
    {
        url: '/auth/getAuthByRole',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": [
                    {
                        "sysAuthId": 1,
                        "sysRoleId": 1,
                        "authType": 1,
                        "authName": "首页",
                        "selectAuth": 0,
                        "addAuth": -1,
                        "updateAuth": -1,
                        "deleteAuth": -1,
                        "exportAuth": -1
                    },
                    {
                        "sysAuthId": 2,
                        "sysRoleId": 1,
                        "authType": 2,
                        "authName": "数据监控",
                        "selectAuth": 0,
                        "addAuth": 0,
                        "updateAuth": -1,
                        "deleteAuth": 0,
                        "exportAuth": -1
                    },
                    {
                        "sysAuthId": 3,
                        "sysRoleId": 1,
                        "authType": 3,
                        "authName": "报警管理",
                        "selectAuth": 0,
                        "addAuth": 0,
                        "updateAuth": 0,
                        "deleteAuth": 0,
                        "exportAuth": -1
                    },
                    {
                        "sysAuthId": 4,
                        "sysRoleId": 1,
                        "authType": 4,
                        "authName": "运维管理",
                        "selectAuth": 0,
                        "addAuth": 0,
                        "updateAuth": 0,
                        "deleteAuth": 0,
                        "exportAuth": -1
                    },
                    {
                        "sysAuthId": 5,
                        "sysRoleId": 1,
                        "authType": 5,
                        "authName": "报告分析",
                        "selectAuth": 0,
                        "addAuth": 0,
                        "updateAuth": 0,
                        "deleteAuth": 0,
                        "exportAuth": -1
                    },
                    {
                        "sysAuthId": 6,
                        "sysRoleId": 1,
                        "authType": 6,
                        "authName": "参数设置",
                        "selectAuth": 0,
                        "addAuth": 0,
                        "updateAuth": 0,
                        "deleteAuth": 0,
                        "exportAuth": -1
                    },
                    {
                        "sysAuthId": 7,
                        "sysRoleId": 1,
                        "authType": 7,
                        "authName": "用户管理",
                        "selectAuth": 0,
                        "addAuth": -1,
                        "updateAuth": -1,
                        "deleteAuth": -1,
                        "exportAuth": 0
                    },
                    {
                        "sysAuthId": 8,
                        "sysRoleId": 1,
                        "authType": 8,
                        "authName": "日志管理",
                        "selectAuth": 1,
                        "addAuth": -1,
                        "updateAuth": -1,
                        "deleteAuth": -1,
                        "exportAuth": -1
                    }

                ]
            })
        },
    },


    {
        url: '/role/addRole',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },
    {
        url: '/user/deleteRoleById',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },
    {
        url: '/role/updateRole',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },
    {
        url: '/role/getRoleDetail',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "sysRoleId": 0,
                    "sysRoleName": "角色名称",
                    "remark": "备注",
                    "status": 0,

                },
            })
        },
    },
    {
        url: '/role/getRoleList',
        type: 'post',
        response: (option: paramsType) => {

            return Mock.mock({
                "code": 0,
                "data|5": [{
                    "sysRoleId|+1": [1, 2, 3, 4, 5],
                    "sysRoleName|+1": ["超级管理员", "普通用户", "超级管理员1", "超级管理员2", "超级管理员3"],
                    "userNum": 0,
                    "status": 0,
                    "remark": "备注"
                }],
            })
        },
    },






    {
        url: '/org/getOrgAll',
        type: 'post',
        response: (option: paramsType) => {
            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "total": 100,
                    "size": 15,
                    "current": 0,
                    "records|10": [{
                        "orgId": 1,
                        "orgNumber": 1,
                        "orgName|+1": ["系统管理员", "运营部部长", "运营部成员", "销售部部长", "系统管理员1", "系统管理员2", "系统管理员3", "系统管理员4", "系统管理员5", "系统管理员6"],
                        "orgType": "组织类型",
                        "checkAll|0-1": 0,
                        "areaEntitys|5": [
                            {
                                "areaId|+1": 1,
                                "areaName": "区域名称"
                            }
                        ]
                    }]
                },
            })
            if (end) {
                responseData.data.records = responseData.data.records.slice(start, end)
            } else {
                return responseData
            }

            return responseData
        },
    },

    {
        url: '/org/addOrg',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },

    {
        url: '/org/updateOrg',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },

    {
        url: '/org/getOrgById',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "orgId": 1,
                    "orgNumber": 1,
                    "orgName": "组织名称",
                    "orgType": "组织类型",
                    "areaEntitys|8": [
                        {
                            "areaId": 1,
                            "areaName|+1": ["液氯库房1", "区域名称2", "区域名称3", "区域名称4", "区域名称5", "区域名称6", "区域名称7", "区域名称8"]
                        }
                    ]
                },
            })
        },
    },

    {
        url: '/org/deleteOrg',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })
        },
    },



]