import { Directive } from "vue";
import { useUserinfoStore } from "@/store/userinfo";
import { getCurrentInstance } from "vue";




const directives: Directive = {
  mounted(el, binding, vnode, preVnode) {
    const { id, permission } = binding.value;
    const userinfo = useUserinfoStore();
    const rule: { [key: string]: string } = {
      show: "selectAuth",
      add: "addAuth",
      update: "updateAuth",
      delete: "deleteAuth",
      export: "exportAuth",
    };

    if (permission && permission instanceof Array) {
      const auth = permission.map((p: string) => {
        let arr: any = userinfo.$state.permission.find((item: any) => item.authType === id)!;
        return arr[rule[p]]
      })
      // if (canShow.includes(false)) {
      //   el.parentElement.style.cursor = "not-allowed"
      //   return
      // }
      // const canDo = permission.map((p: string) => {
      //   let arr: any = userinfo.$state.permission.find((item: any) => item.authType === id)!;
      //   if (arr[rule[p]]) return false;
      // })
      // if (canDo.includes(false)) {
      //   el.parentElement.removeChild(el)
      // } else if (canShow.includes(true)) {

      // }
      if (auth.includes(0)) {
        return
      } else if (auth.includes(1)) {
        if (el.parentNode) {
          let cloneNode = el.cloneNode(true)
          cloneNode.classList.add("auth-not-allowed")
          el.parentElement.replaceChild(cloneNode, el);
        }
      } else if (auth.includes(-1)) {
        el.parentElement.removeChild(el)
      }


    }
  },
};

export default directives;
