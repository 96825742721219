import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: '/patrolInspectionPlan/getALLPatrolInspectionPlan',
        type: 'post',
        response: (option: paramsType) => {
            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "total": 100,
                    "size": 15,
                    "records|100": [{
                        "cycle": 5,
                        "patrolInspectionPlanId|+1": 1,
                        "areaId": 1,
                        "plannedStartTime": "1981-10-15 05:13:11",
                        "createTime": "1974-09-25 06:04:18",
                        "state": 1,
                        "content": "内容",
                        "sysUserId": 1,
                        "remarks": "备注",
                        "plannedName": "任务名称",
                        "userName": "用户名称",
                        "plannedEndTime": "1993-06-12 08:06:13",
                        "areaName": "区域名称"
                    }]
                },
            })
            responseData.data.records = responseData.data.records.slice(start, end)
            return responseData
        },
    },
    {
        url: '/patrolInspectionPlan/getPatrolTask',
        type: 'post',
        response: (option: paramsType) => {
            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "total": 100,
                    "size": 15,
                    "records|100": [{
                        "cycle": 5,
                        "patrolInspectionPlanId|+1": 1,
                        "areaId": 1,
                        "plannedStartTime": "1981-10-15 05:13:11",
                        "createTime": "1974-09-25 06:04:18",
                        "state": 10,
                        "content": "内容",
                        "sysUserId": 1,
                        "remarks": "备注",
                        "plannedName": "任务名称",
                        "userName": "用户名称",
                        "plannedEndTime": "1993-06-12 08:06:13",
                        "areaName": "区域名称"
                    }]
                },

            })
            responseData.data.records = responseData.data.records.slice(start, end)
            return responseData
        },
    },
    {
        url: '/patrolInspectionPlan/getPatrolTaskRecord',
        type: 'post',
        response: (option: paramsType) => {
            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "total": 100,
                    "size": 15,
                    "records|100": [{
                        "cycle": 5,
                        "patrolInspectionPlanId": 1,
                        "areaId": 1,
                        "plannedStartTime": "1981-10-15 05:13:11",
                        "createTime": "1974-09-25 06:04:18",
                        "state": 1,
                        "content": "内容",
                        "sysUserId": 1,
                        "remarks": "备注",
                        "plannedName": "任务名称",
                        "userName": "用户名称",
                        "plannedEndTime": "1993-06-12 08:06:13",
                        "areaName": "区域名称",
                        "actualStartTime": "1993-06-12 08:06:13",
                        "actualEndTime": "1993-06-12 08:06:13"
                    }]
                },

            })
            responseData.data.records = responseData.data.records.slice(start, end)
            return responseData
        },
    },
    {
        url: '/patrolInspectionPlan/getPatrolInspectionPlanDetail',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "cycle": 10,
                    "patrolInspectionPlanId": 12,
                    "areaId": 22,
                    "plannedStartTime": "2006-10-16 07:38:26",
                    "createTime": "2002-10-31 20:32:10",
                    "state": 64,
                    "content": "eiusmod veniam et fugiat",
                    "sysUserId": 72,
                    "remarks": "consectetur aliquip",
                    "plannedName": "什南术压情才",
                    "userName": "彭艳",
                    "plannedEndTime": "2011-02-11 18:32:11",
                    "areaName": "还观导才回"
                },

            })

        },
    },
    {
        url: '/patrolInspectionPlan/updatePatrolInspectionPlan',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },

            })

        },
    },
    {
        url: '/patrolInspectionPlan/addPatrolInspectionPlan',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },

            })

        },
    },
    {
        url: '/patrolInspectionPlan/deletePatrolInspectionPlan',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },

            })

        },
    },
]