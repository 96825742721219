import { RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
    // {
    //     path: 'demo',
    //     meta: {
    //         title: '测试页面'
    //     },
    //     component: () => import('@/pages/common/demo/index.vue'),
    // },
    {
        path: 'home',
        meta: {
            title: '仪表盘'
        },
        component: () => import('@/pages/home/index.vue'),
    },
    {
        path: 'report',
        meta: {
            title: '报表'
        },
        redirect: '/main/report/monthly',
    },
    {
        path: 'report/monthly',
        meta: {
            title: "月度报告"
        },
        component: () => import('@/pages/report/monthly_report/index.vue')
    },
    {
        path: 'reset/password',
        meta: {
            title: '密码重置'
        },
        component: () => import('@/pages/common/resetPSW/index.vue')
    },
    {
        path: 'home',
        meta: {
            title: '仪表盘',
        },
        component: () => import('@/pages/home/index.vue'),
    },
    {
        path: 'monitor/:type',
        meta: {
            title: '数据监控',
        },
        component: () => import('@/pages/dataMonitor/index.vue'),
    },


];

export default routes;