import { RouteRecordRaw } from 'vue-router'
import { useUserinfoStore } from '@/store/userinfo'

const userinfo = useUserinfoStore()

const routes: Array<RouteRecordRaw> = [
    {
        path: 'params/base',
        meta: {
            title: '基础数据维护'
        },
        component: () => import('@/pages/paramsSetting/baseData/index.vue')
    },
    {
        path: 'params/base/add',
        meta: {
            title: '基础数据维护'
        },
        component: () => import('@/pages/paramsSetting/baseData/add.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(6, "add", next)
        }

    },
    {
        path: 'params/base/edit',
        meta: {
            title: '基础数据维护'
        },
        component: () => import('@/pages/paramsSetting/baseData/edit.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(6, "edit", next)
        }
    },
    {
        path: 'params/alarm',
        meta: {
            title: '基础数据维护'
        },
        component: () => import('@/pages/paramsSetting/alarmParams/index.vue')
    },
];

export default routes;