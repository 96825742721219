import { createApp } from "vue";
import { createPinia } from "pinia";
import { Menu } from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import ViewUIPlus from "view-ui-plus";
// pinia 数据持久化
import piniaPluginPersist from "pinia-plugin-persist";
// 定制主题颜色则不启用默认样式表
// import 'view-ui-plus/dist/styles/viewuiplus.css'
import "@/assets/styles/theme/index.less";
import "animate.css";

// 全局样式
import "@/assets/styles/global/index.less";
// 离线下测试用，正式以及和后台联调需要注释
import { MockStart } from "@/mock";
import hasPermission from "@/utils/directives/hasPermission";
import { useRouteMapStore } from "./store/route";
import { useRoute } from "vue-router";
import 'amfe-flexible'


if (process.env.VUE_APP_MOCK) {
  MockStart();
}


const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersist);


app.use(router).use(pinia).use(ViewUIPlus).use(Menu).mount("#app");
app.directive("hasPermission", hasPermission);













