import { RouteRecordRaw } from 'vue-router'
import { useUserinfoStore } from '@/store/userinfo'

const userinfo = useUserinfoStore()

const routes: Array<RouteRecordRaw> = [
    {
        path: 'users/account',
        meta: {
            title: '账号管理'
        },
        component: () => import('@/pages/userManage/account/index.vue')
    },
    {
        path: 'users/account/add',
        meta: {
            title: '新增用户'
        },
        component: () => import('@/pages/userManage/account/add.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(7, "add", next)
        }
    },
    {
        path: 'users/account/edit',
        meta: {
            title: '编辑用户'
        },
        component: () => import('@/pages/userManage/account/edit.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(7, "edit", next)
        }
    },
    {
        path: 'users/role',
        meta: {
            title: '角色权限'
        },
        component: () => import('@/pages/userManage/role/index.vue')

    },
    {
        path: 'users/role/edit',
        meta: {
            title: '编辑角色'
        },
        component: () => import('@/pages/userManage/role/edit.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(7, "edit", next)
        }
    },
    {
        path: 'users/role/add',
        meta: {
            title: '添加角色'
        },
        component: () => import('@/pages/userManage/role/add.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(7, "add", next)
        }
    },
    {
        path: 'users/role/manage',
        meta: {
            title: '权限管理'
        },
        component: () => import('@/pages/userManage/role/manage.vue')
    },
    {
        path: 'users/department',
        meta: {
            title: '组织管理'
        },
        component: () => import('@/pages/userManage/department/index.vue')

    },
    {
        path: 'users/department/add',
        meta: {
            title: '新增组织'
        },
        component: () => import('@/pages/userManage/department/add.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(7, "add", next)
        }
    },
    {
        path: 'users/department/edit',
        meta: {
            title: '编辑组织'
        },
        component: () => import('@/pages/userManage/department/edit.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(7, "edit", next)
        }
    },
];

export default routes;