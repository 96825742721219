import { RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/soft',
    name: 'soft',
    meta: {
      title: '登录页'
    },

    component: () => import('@/pages/common/login/login.vue')
  },
  {
    path: '/soft2',
    name: 'soft2',
    meta: {
      title: '登录页'
    },
    component: () => import('@/pages/common/login/login.vue')
  },

];

export default routes;