import { createRouter, createWebHistory, RouteRecordRaw, useRouter } from 'vue-router'
import Layout from '../layout/layout.vue'
import Common from './modules/common'
import { useUserinfoStore } from '@/store/userinfo'
import { onMounted } from 'vue'
import routeMap from "@/router/map";
import MenuList from '@/components/menu/menulist'

import { useRouteMapStore } from '@/store/route'
import { useGlobalStore } from '@/store/global'

// 自动引入模块目录下内容(该方法适用于webpack)

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    component: Layout,
    name: 'main',
    path: '/main',
    redirect: '/main/home',
    children: [
      {
        path: 'demo/ddd',
        meta: {
          title: '测试页面',
        },
        component: () => import('../pages/common/demo/index.vue'),
      },
      {
        path: "password/reset",
        meta: {
          title: "密码重置"
        },
        component: () => import('@/pages/common/resetPSW/index.vue')
      },

      // ...AutoImportModules(),
      // ...ImportMainMoudles(),
      {
        path: '/main/:pathMatch(.*)',
        meta: {
          title: '找不到该页面',
        },
        component: () => import('@/pages/common/404.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      if (localStorage.hasOwnProperty("userinfo")) {
        let info = JSON.parse(localStorage.getItem('userinfo') as any).userinfo
        if (Object.keys(info).length !== 0) {
          next()
        } else {
          window.location.href = '/login'
        }
      } else {
        window.location.href = '/login'
      }

    }
  },
  ...Common,

  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
    meta: {
      title: '找不到该页面',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})



router.beforeEach((to, from, next) => {
  let initRoute = <any>[]
  routes.map((item) => {
    if (item.children) {
      item.children.map((child) => {
        initRoute.push(child)
      })
    }
    initRoute.push(item)
  })
  if (router.getRoutes().length === initRoute.length) {
    const userinfo = useUserinfoStore();
    userinfo.MATCH_ROUTE()
    router.push(to.path)
  } else {
    next()
  }
  MenuList.map((item) => {
    if (item.children) {
      item.children.map((child) => {
        if (to.fullPath === child.link) {
          useGlobalStore().changeMenuActive([child.id])
        }
      })
    } else {
      if (to.fullPath === item.link) {
        useGlobalStore().changeMenuActive([item.id])
      }
    }

  })

  if (to.meta.title) {
    //判断是否有标题
    window.document.title = '驰宏锗业 - ' + to.meta.title
  } else {
    document.title = '驰宏锗业 - 智慧产区数据综合管理平台'
  }
})

export default router


