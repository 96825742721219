import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: '/sonsorData/getSonsorDataByArea',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|6": [{
                    "sonsorId|+1": 1,
                    "sonsorName": "传感器名称",
                    "sonsorData": 2.33
                }]
            })
        }
    },
    {
        url: '/sonsorData/getSonsorDataBySonsor',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|10": [{
                    "sonsorDataId": 1,
                    "sonsorValue|1": "@float(1,200,0,0)",
                    "createTime|+1": ["2012-12-21 16:54:21",
                        "2012-1-21 11:33:21", "2012-3-21 22:3:21",
                        "2012-7-21 23:21:21", "2012-8-21 2:55:21"
                        , "2012-5-21 12:45:21", "2012-2-21 17:43:21",
                        "2012-6-21 1:11:21", "2012-7-21 22:33:21", "2012-11-21 16:28:21"
                    ]
                }]
            })
        }
    },
    {
        url: '/sonsorData/getDataPage',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "size": 10,
                    "total": 10,
                    "current": 1,
                    "records|8": [
                        {
                            "createTime": "@date",
                            "sonsorValue|100-200": 100,
                            "isAlarm|0-1": 0
                        }
                    ]
                }
            })
        }
    },
    {
        url: '/sonsorData/getSonsorDataBySonsor',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": [{
                    "sonsorDataId": 1,
                    "sonsorValue": 2.44,
                    "createTime": "2012-12-12 12:12:12"
                }]
            })
        }
    },
    {
        url: '/sonsorData/getSonsorDataExport',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": [{
                }]
            })
        }
    },

]