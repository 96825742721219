import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: '/sonsor/getAllSonsor',
        type: 'post',
        response: (option: paramsType) => {
            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "total": 100,
                    "size": 15,
                    "records|100": [{
                        "sonsorId|+1": 1,
                        "sonsorName": "设备名称",
                        "areaName": "区域名称",
                        "remark": "描述",
                        "sonsorCode|+1": 1,
                        "createTime": "2012-12-11 12:12:12",
                        "areaId|+1": 1
                    }]
                },
            })
            responseData.data.records = responseData.data.records.slice(start, end)
            return responseData
        },
    },
    {
        url: '/sonsor/getSonsorById',
        type: 'post',
        response: (option: paramsType) => {
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "sonsorId": 1,
                    "sonsorName": "设备名称",
                    "areaId": 1,
                    "areaName": "区域名称",
                    "remark": "描述",
                    "sonsorCode": "设备ID"
                },
            })
            return responseData
        },
    },
    {
        url: '/sonsor/saveSonsor',
        type: 'post',
        response: (option: paramsType) => {
            let responseData = Mock.mock({
                "code": 0,
                "msg": "成功"
            })
            return responseData
        },
    },
    {
        url: '/sonsor/updateSonsor',
        type: 'post',
        response: (option: paramsType) => {
            let responseData = Mock.mock({
                "code": 0,
                "msg": "成功"
            })
            return responseData
        },
    },
    {
        url: '/sonsor/deleteSonsor',
        type: 'post',
        response: (option: paramsType) => {
            let responseData = Mock.mock({
                "code": 0,
                "msg": "删除成功"
            })
            return responseData
        },
    },
    {
        url: '/alarmParameterSetting/updateAlarmParameterSetting',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "成功"
                },
            })

        },
    },
    {
        url: '/alarmParameterSetting/getAlarmParameterSetting',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|20": [{
                    "sonsorName": "设备1",
                    "alarmParameterSettingId": 1,
                    "sonsorId": 1,
                    "parameterName": "参数名称1",
                    "alarmThreshold": 1.99,
                    "alarmDelay": 3,
                    "alarmRemovalThreshold": 1.88,
                    "openAlarm": 0

                }],
            })
        },
    },
    {
        url: '/alarmParameterSetting/startAlarm',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data": {
                    "code": 0,
                    "msg": "开启成功报警"
                },
            })

        },
    },
]