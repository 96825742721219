import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: '/workOrderDistribution/getAlarmTrend',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|8": [{
                    "alarmNum|30-80": 50,
                    "createTime": "@date"
                }]
            })
        }
    },
    {
        url: '/workOrderDistribution/getAlarmRank',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|10": [{
                    "sort|+1": 1,
                    "areaName": "区域名称",
                    "sonsorNum|20-100": 1,
                    "alarmNum|20-100": 1
                }]
            })
        }
    },
    {
        url: '/patrolInspectionPlan/getPatrolTasksituation',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|10": [{
                    "plannedName": "计划名称",
                    "state": 5,
                    "userName": "巡检人名称"
                }]
            })
        }
    },
]