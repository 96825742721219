import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: '/area/getAreaDate',
        type: 'post',
        response: (option: paramsType) => {
            return Mock.mock({
                "code": 0,
                "data|8": [{
                    "sort|+1": 1,
                    "areaName|+1": ["液氯库房1", "液氯库房3", "液氯库房2", "液氯库房4", "液氯库房5", "液氯库房6", "液氯库房7",]
                }]
            })
        }
    }
]