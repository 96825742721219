import Mock from 'mockjs';


type paramsType = {
    url: string,
    type: string,
    body: string,
}
export default [
    {
        url: 'api/mock/log/data',
        type: 'post',
        response: (option: paramsType) => {
            let params = JSON.parse(option.body)
            let start = (params.current - 1) * params.size
            let end = params.current * params.size
            let responseData = Mock.mock({
                "code": 0,
                "data": {
                    "total": 100,
                    "size": 15,
                    "current": 1,
                    "records|100": [{
                        "sysLogId|+1": 1,
                        "nickName": "@cname",
                        "username": "ppx",
                        "sysUserId": 1,
                        "ipAddress": "127.0.0.1",
                        "content": "登录",
                        "creatTime": "2023-02-01 23:02:16"
                    }]
                }
            })
            responseData.data.records = responseData.data.records.slice(start, end)
            return responseData

        }
    }
]