import { RouteRecordRaw } from 'vue-router'
import { useUserinfoStore } from '@/store/userinfo'

const userinfo = useUserinfoStore()

const routes: Array<RouteRecordRaw> = [
    {
        path: 'alarm/list',
        meta: {
            title: '报警列表'
        },
        component: () => import('@/pages/alarmManage/list/index.vue')
    },
    {
        path: 'alarm/list/sent',
        meta: {
            title: '工单派发'
        },
        component: () => import('@/pages/alarmManage/list/sent.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(3, "edit", next)
        }
    },
    {
        path: 'alarm/list/detail',
        meta: {
            title: '报警详情'
        },
        component: () => import('@/pages/alarmManage/list/detail.vue')
    },
    {
        path: 'alarm/handle',
        meta: {
            title: '报警处理'
        },
        component: () => import('@/pages/alarmManage/handle/index.vue')
    },
    {
        path: 'alarm/handle/detail/',
        meta: {
            title: '报警处理详情'
        },
        component: () => import('@/pages/alarmManage/handle/detail.vue')
    },
    {
        path: 'alarm/keypoint',
        meta: {
            title: '重点报警'
        },
        component: () => import('@/pages/alarmManage/keypoint/index.vue')
    },
    {
        path: 'alarm/analysis',
        meta: {
            title: '报警分析'
        },
        component: () => import('@/pages/alarmManage/analysis/index.vue')
    },
];

export default routes;