import Mock from 'mockjs'

export default [
  {
    url: "api/mock/home/get",
    type: "post",
    response: () => {
      return Mock.mock({
        "code": 0,
        "data":
        {
          // 数据预览
          "overview": {
            "facility|50-100": 100,          // 设备总数
            "alarm|20-100": 100,             // 报警总数
            "alarm_today|0-50": 50,          // 今日报警总数
            "patrol|0-10": 10,               // 巡检次数
            "unprocessed|0-20": 20,          // 未处理报警数量
            "unprocessed_today|0-10": 10,    // 今日未处理报警数量
          },
          // 设备状态
          "facility_status": {
            "normal|50-100": 100,    // 正常设备
            "alarm|10-50": 50,       // 报警设备
            "offline|0-30": 30       // 离线设备
          },
          // 设备分布
          "distribution|7": [
            {
              "facility|+1":["液氯库房", "光纤复蒸房", "氯气库房", "制氢站房", "氯化氢房", "区融还原房", "区融还原房"],
              "count|1-15":15,
            }
          ],
          // 报警分类
          "alarm_class|7": [
            {
              "alarm|+1":["液氯库房报警", "光纤复蒸房报警", "氯气库房报警", "制氢站房报警", "氯化氢房报警", "区融还原房报警", "区融还原房报警"],
              "percent|1-100":100,
            }
          ],
          // 当月设备报警
          "facility_alarm_month|1-10":[
            {
              "date": "@now(yyyy-MM-dd)",
              "position|+1": ["液氯库房A,氯气浓度超标", "液氯库房B,氯气浓度超标", "液氯库房C,氯气浓度超标", "变电房A,温度超标", "变电房B,温度超标"],
              "status|+1": ["未派发", "已处理", "未处理", "已派发, 未处理"],
            }
          ],
          // 近7天报警统计
          "recent_week|7":[
            {
              "date":"@now(yyyy-MM-0)@increment(1)",
              "processed|0-10":1,
              "unprocess|0-10":1,
            }
          ],
        }
      })
    }
  },
  {
    url: "api/mock/home/init",
    type: "post",
    response: () => {
      return {
        "code": 0,
        "data":
        {
          "message": "success"
        }
      }
    }
  },
];