import { RouteRecordRaw } from 'vue-router'


const common: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录页'
        },
        component: () => import('@/pages/common/login/login.vue'),
        beforeEnter: (to, from, next) => {
            if (localStorage.hasOwnProperty("userinfo")) {
                let info = JSON.parse(localStorage.getItem('userinfo') as any)
                let userinfo = info.userinfo
                if (Object.keys(userinfo).length == 0) {
                    next()
                } else {
                    window.location.href = '/main'
                }
            } else {
                next()
            }


        }
    },

    {
        path: '/404',
        name: 'NotFound-Global',
        meta: {
            title: '找不到该页面'
        },
        component: () => import('@/pages/common/404.vue')
    },
];

export default common;