import Mock from "mockjs";
import { MockParams } from "./type";

// 自动引入模块目录下内容(该方法适用于webpack)
const AutoImportModules = (): Array<MockParams> => {
    let moduleArr: Array<MockParams> = [];

    const moduleFile = require.context('./modules/', true, /\.ts$/);
    moduleFile.keys().map(file => {
        moduleArr.push(...moduleFile(file).default)
    })
    return moduleArr;
}

const moduleArr = AutoImportModules();

Mock.setup({
    timeout: "300",
});

// console.log(moduleArr);


export function MockStart() {
    let i: MockParams;
    for (i of moduleArr) {
        Mock.mock(new RegExp(i.url), i.type || "get", i.response);
    }
}