import { RouteRecordRaw } from 'vue-router'
import { useUserinfoStore } from '@/store/userinfo'

const userinfo = useUserinfoStore()




const routes: Array<RouteRecordRaw> = [
    {
        path: 'inspection/plan',
        meta: {
            title: '巡检计划'
        },
        component: () => import('@/pages/operationManage/inspectionPlan/index.vue')
    },
    {
        path: 'inspection/plan/add',
        meta: {
            title: '添加巡检计划'
        },
        component: () => import('@/pages/operationManage/inspectionPlan/add.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(4, "add", next)
        }
    },
    {
        path: 'inspection/plan/edit',
        meta: {
            title: '编辑计划详情'
        },
        component: () => import('@/pages/operationManage/inspectionPlan/edit.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(4, "edit", next)
        }
    },
    {
        path: 'inspection/task',
        meta: {
            title: '巡检任务'
        },
        component: () => import('@/pages/operationManage/inspectionTask/index.vue')
    },
    {
        path: 'inspection/task/edit',
        meta: {
            title: '巡检记录'
        },
        component: () => import('@/pages/operationManage/inspectionTask/edit.vue'),
        beforeEnter: (to, from, next) => {
            userinfo.MATHCH_AUTH(4, "edit", next)
        }
    },
    {
        path: 'inspection/record',
        meta: {
            title: '巡检记录'
        },
        component: () => import('@/pages/operationManage/inspectionRecord/index.vue')
    },



];

export default routes;