import Mock from 'mockjs'

export default [
    {
        url: "/api/auth/getAuthByRole",
        type: "post",
        response: () => {
            return Mock.mock(response)
        }
    }
];

const response = {
    "code": 0,
    "data": [
        {
            "sysAuthId": 1,
            "sysRoleId": 1,
            "authType": 1,
            "authName": "首页",
            "selectAuth": 0,
            "addAuth": -1,
            "updateAuth": -1,
            "deleteAuth": -1,
            "exportAuth": -1
        },
        {
            "sysAuthId": 2,
            "sysRoleId": 1,
            "authType": 2,
            "authName": "数据监控",
            "selectAuth": 0,
            "addAuth": 0,
            "updateAuth": -1,
            "deleteAuth": 0,
            "exportAuth": -1
        },
        {
            "sysAuthId": 3,
            "sysRoleId": 1,
            "authType": 3,
            "authName": "报警管理",
            "selectAuth": 0,
            "addAuth": 0,
            "updateAuth": 0,
            "deleteAuth": 0,
            "exportAuth": -1
        },
        {
            "sysAuthId": 4,
            "sysRoleId": 1,
            "authType": 4,
            "authName": "运维管理",
            "selectAuth": 0,
            "addAuth": 0,
            "updateAuth": 0,
            "deleteAuth": 0,
            "exportAuth": -1
        },
        {
            "sysAuthId": 5,
            "sysRoleId": 1,
            "authType": 5,
            "authName": "报告分析",
            "selectAuth": 0,
            "addAuth": 0,
            "updateAuth": 0,
            "deleteAuth": 0,
            "exportAuth": -1
        },
        {
            "sysAuthId": 6,
            "sysRoleId": 1,
            "authType": 6,
            "authName": "参数设置",
            "selectAuth": 0,
            "addAuth": 0,
            "updateAuth": 0,
            "deleteAuth": 0,
            "exportAuth": -1
        },
        {
            "sysAuthId": 7,
            "sysRoleId": 1,
            "authType": 7,
            "authName": "用户管理",
            "selectAuth": -1,
            "addAuth": -1,
            "updateAuth": -1,
            "deleteAuth": -1,
            "exportAuth": 0
        },
        {
            "sysAuthId": 8,
            "sysRoleId": 1,
            "authType": 8,
            "authName": "日志管理",
            "selectAuth": -1,
            "addAuth": -1,
            "updateAuth": -1,
            "deleteAuth": -1,
            "exportAuth": -1
        }
    ]
}